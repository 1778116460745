// Main Javascript file that is used by webpack to build the /public/js/main.js file.

/**
 * Import Bootstrap and popper.js JavaScript
 */
import 'bootstrap';
import '@popperjs/core';

// Enable popovers
import { Popover } from 'bootstrap';
document.addEventListener('DOMContentLoaded', function () {
  const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]');
  popoverTriggerList.forEach(function (popoverTriggerEl) {
    new Popover(popoverTriggerEl);
  });
});

// Enable tooltips
import { Tooltip } from 'bootstrap';
document.addEventListener('DOMContentLoaded', function () {
  const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
  tooltipTriggerList.forEach(function (tooltipTriggerEl) {
    new Tooltip(tooltipTriggerEl);
  });
});

// Import custom scripts
import './_client_main';