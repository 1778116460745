// Main Client Script goes here

// Logout function
function logout() {
  fetch('/logout', { method: 'POST' })
    .then(() => {
      window.location.href = '/';
    })
    .catch((error) => {
      console.error('Logout error:', error);
    });
}

console.log('_client main loaded...')


document.addEventListener('DOMContentLoaded', function () {
  const logoutButton = document.getElementById('logout-button');
  if (logoutButton) {
    logoutButton.addEventListener('click', logout);   
  }
});



// Toggle Navbar item 'active'
$(function() {
  // Get the current page URL
  var currentUrl = window.location.href;

  // Get all the navbar links
  var navLinks = $('.navbar-nav').find('.nav-link');

  // Loop through each navbar link
  navLinks.each(function() {
    // Get the link URL
    var linkUrl = $(this).attr('href');

    // Check if the link URL matches the current page URL
    if (currentUrl.indexOf(linkUrl) !== -1) {
      // Add the 'active' class to the link
      $(this).addClass('active');
    } else {
      // Remove the 'active' class from other links
      $(this).removeClass('active');
    }
  });
});


module.exports = {
  logout
}
